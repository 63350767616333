.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  &:focus {
    @apply ring-0;
  }
}

body {
  background: #7533ae; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #7533ae, #00dbde); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #7533ae,
    #00dbde
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

// Hamburger button
.hamburger {
  svg > * {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      transform-origin: center;
      transform: rotate(0deg);
    }

    &:nth-child(1) {
      transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
    }

    &:nth-child(2) {
      transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:nth-child(3) {
      transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
    }
  }

  &.active {
    svg > * {
      &:nth-child(1) {
        opacity: 0;
        y: 11;
        transform: rotate(225deg);
        transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.1s 0.12s ease-out;
      }

      &:nth-child(2) {
        transform: rotate(225deg);
        transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &:nth-child(3) {
        y: 11;
        transform: rotate(135deg);
        transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
      }
    }
  }
}

// Pulsing animation
@keyframes pulseLoop {
  0% {
    opacity: 0;
    transform: scale(0.1) translateZ(0);
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2) translateZ(0);
  }
}
.pulse {
  transform: scale(0.1);
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 8000ms linear infinite;
}
.pulse-1 {
  animation-delay: -2000ms;
}
.pulse-2 {
  animation-delay: -4000ms;
}
.pulse-3 {
  animation-delay: -6000ms;
}
